import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

function Teacher_Exam_Result() {

  const [showOption, setShowOption] = useState(false);


  return (
    <div>
      <h5 className={"Time_Table"}>Exam result</h5>

      <div className="flerojubet">
        <div>
      <h5 className={"MobiExaResu"}>Exam result</h5>
</div>
      <div className="totuni_re_Mobi">
            Unit Test 1 &nbsp; 
            {showOption ? (
              <IoIosArrowUp
                onClick={() => {
                  setShowOption(false);
                }}
                className="seconary-navbar-icons"
              />
            ) : (
              <IoIosArrowDown
                onClick={() => {
                  setShowOption(true);
                }}
                className="seconary-navbar-icons"
              />
            )}
          </div></div>

      <div className="ex_recard">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column gapExresu">
            <div className="totex_re">Total</div>
            <div>
              <span className="theeigex_re">380</span>
              <span className="fivex_re">/500</span>
            </div>
          </div>
          <div className="totuni_re">
            Unit Test 1 &nbsp; 
            {showOption ? (
              <IoIosArrowUp
                onClick={() => {
                  setShowOption(!showOption);
                }}
                className="seconary-navbar-icons"
              />
            ) : (
              <IoIosArrowDown
                onClick={() => {
                  setShowOption(!showOption);
                }}
                className="seconary-navbar-icons"
              />
            )}
          </div>
        </div>

        { showOption && (
                        <div className='d-flex flex-column inter-font-500 QuestionType_Options_list' >
                            <div className='dropdown-profile-sub-title'>
                            Session 1  
                            </div>
                            <div className='dropdown-profile-sub-title'>
                            Session 2  
                            </div>
                            <div className='dropdown-profile-sub-title'>
                            Session 3  
                            </div>
                            <div className='dropdown-profile-sub-title'>
                            Session 4  
                            </div>
                        </div>
                      )} 

        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">English</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "70%",
              }}
              className="coverthreejai111"
            ></div>
          </div>

          <div className="numex_re">70/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Tamil</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "30%",
              }}
              className="coverthreejai444 "
            ></div>
          </div>

          <div className="numex_re">30/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Maths</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "90%",
              }}
              className="coverthreejai111"
            ></div>
          </div>

          <div className="numex_re">90/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Physics</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "64%",
              }}
              className="coverthreejai111"
            ></div>
          </div>

          <div className="numex_re">64/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Chemistry</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "20%",
              }}
              className="coverthreejai444"
            ></div>
          </div>

          <div className="numex_re">20/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Botany</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "80%",
              }}
              className="coverthreejai111"
            ></div>
          </div>

          <div className="numex_re">80/100</div>
        </div>
        <div className="d-flex flex-row justify-content-evenly MariOExResul">
          <div className="lanex_re">Zoology</div>

          <div className="coverflexonejai">
            <div
              style={{
                width: "72%",
              }}
              className="coverthreejai111"
            ></div>
          </div>

          <div className="numex_re">72/100</div>
        </div>
      </div>
    </div>
  );
}

export default Teacher_Exam_Result;
