import React,{Fragment} from 'react'
import "./KButton.css"
function KButton({label,isDisabled,value,onClick,className,style,btnColor,disabledBtnColor,txtColor,disabledtxtColor,LeftIcon,RightIcon,...props}) {
  return (
   <button style={{...style,backgroundColor:isDisabled ? disabledBtnColor :btnColor,color : isDisabled ? disabledtxtColor :txtColor}} className={`KButton__container  fw-semibold ${className} ${isDisabled ? 'not-allowed' :'cursor-ponter'}`} onClick={(event)=>{
    if(!isDisabled){
        onClick(event)
    }
    }} {...props}>{LeftIcon}{label !== '' ? label : value}{RightIcon}</button>
  )
}

KButton.defaultProps = {
    label: '',
    value: '',
    onClick: () => {},
    className: '',
    style: {},
    btnColor : '#2684ff',
    txtColor : '#ffffff',
    isDisabled :false,
    disabledBtnColor : '#cccccc',
    disabledtxtColor : '#666666',
    RightIcon : <Fragment></Fragment>,
    LeftIcon : <Fragment></Fragment>
}

export default KButton