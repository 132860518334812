import React from 'react'

function KRequiredLabel({className,style,isRequired,title}) {
  return (
    <label className={`h5 width-fit ${className} ${isRequired ? 'required-field' : ''}`}
    style={{...style}}
>{title}</label>
  )
}

KRequiredLabel.defaultProps = {
    isRequired: true,
    className:'',
    style:{},
}

export default KRequiredLabel