import React from 'react'

function FormHeader(props) {
  return (
    <div>
        <h2 id="headerTitle">{props.title}</h2>
        </div>
  )
}

export default FormHeader