import React from 'react'
import "./KHeader.css"
function KHeader({title,containerClassName,styles,frontElement,LastElement}) {
  return (
    <div style={styles} className={`d-flex flex-row justify-content-between  marginLeft-20 ${containerClassName}`}>
    {frontElement}
    <div className="d-flex flex-row">
      <h5 className={"KHeader__title"}>{title}</h5>
    </div> 
    {LastElement}
  </div>
  )
}
KHeader.defaultProps = {
    title: '',
    styles :{},
    LastElement : <React.Fragment></React.Fragment>,
    frontElement : <React.Fragment></React.Fragment>
}
export default KHeader