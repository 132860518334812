import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { IoIosArrowForward } from "react-icons/io";

function Example() {
    var items = [
        {
            image: "https://res.cloudinary.com/vairam24/image/upload/v1658816033/Sports_Day_qrnqij.png",
            name: "School Sports day",
            description: "View more"
        },
        {
            image: "https://res.cloudinary.com/vairam24/image/upload/v1658816033/chess_competition_pxxggn.jpg",
            name: "School Chess Competition ",
            description: "View more"
        },
        {
            image: "https://res.cloudinary.com/vairam24/image/upload/v1658816033/NCC_Day_gvezyk.jpg",
            name: "School NCC Day",
            description: "View more"
        },
        {
            image: "https://res.cloudinary.com/vairam24/image/upload/v1658816033/Library_Day_a94fxs.jpg",
            name: "School Library Day",
            description: "View more"
        },
        {
            image: "https://res.cloudinary.com/vairam24/image/upload/v1658816033/Speech_competition_gvlohh.jpg",
            name: "School Speech competition",
            description: "View more"
        },
    ]
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const current = new Date();
    const date = ` ${current.getDate()} ${months[current.getMonth()]} ${current.getFullYear()}`;  
  return (
    <div>
        <Carousel style={{borderRadius:" 50px"}}>                         
            {
                items.map( (item, i) => 
                <div>
                     <p className='paraimg_fir'>
            {date}
        </p>
            <p className='paraimg_sec'>
                {item.name}
            </p>
            
            <p className='paraimg_thi'>
                 {item.description} <IoIosArrowForward/>
            </p>
            {/* <div className="paraimg_back"></div> */}

                <img src={item.image} className='examinmghj'/>
                </div>
                )
            }
        </Carousel>
    </div>
  )
}

export default Example