import React from 'react'

function FormButton(props) {
  return (
    <div>
        <div id="button" className={"logBtn_card"}>
    <button className={"loginBtn_Qit"} type="submit"
    >{props.title}</button>
  </div>
    </div>
  )
}

export default FormButton