import React from 'react'
import { BiCheck } from 'react-icons/bi'

const Success = (props) => {
    const { body } = props
    return (
        <div className={"row"}>
            <div
                className={"col-lg-2 col-md-2 col-sm-2"}
                style={{
                    float: "left",
                    width: "45px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignSelf: "center"
                }}
            ><BiCheck
                    style={{
                        backgroundColor: "#fff",
                        color: "green",
                        borderRadius: "50px",
                        marginLeft: "10px"
                    }}
                    size={30} /> </div>
            <div className={"col-lg-9 col-md-9 col-sm-9"} style={{ paddingTop: 10 }} >
                <h1 style={{ fontSize: "17px", display: "flex" }}> {body} </h1>
            </div>
        </div>
    )
}

export default Success
