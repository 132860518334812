import React from 'react'

function FormInput(props) {
  return (
    <div className={"row login_Inp_card"}>
    <label><props.textIcon size="14" />&nbsp;{props.description}</label>
    <input type={props.type}
      ref={props.reference}
      value={props.value}
      onChange={((e)=>{
        props.func(e.target.value)
      })}
    placeholder={props.placeholder}/>
  </div>
  )
}

export default FormInput